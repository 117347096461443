import * as React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {Component} from "react";
import {Helmet} from "react-helmet";

export class ScheduleDemo extends Component {

    componentDidMount() {
        this.initializeHubspotForm();
    }

    initializeHubspotForm() {
        if ('hbspt' in window) {
            window.hbspt.forms.create({
                region: "eu1",
                portalId: "25215065",
                formId: "2268a80a-3024-477b-9475-762b35f56193",
                target: "#formContainer"
            });
        } else {
            setTimeout(this.initializeHubspotForm, 500)
        }
    }


    render() {
        return (
            <Layout>

                <section className={"form"}>
                    <aside>
                        <h1>Schedule a demo with us<br/>
                            & Explore the Credential Cloud</h1>
                        <p>Thank you for your interest in Hyperstack Credential Cloud.<br/>Learn how to,</p>
                        <ul className={"list"}>
                            <li>Issue verifiable credentials.</li>
                            <li>Bulk issue credentials within minutes.</li>
                            <li>Turn your learners into brand ambassadors.</li>
                            <li>Engage your learners with a personal wallet</li>
                            <li>Achieve maximum success rate with mind-share loop.</li>
                            <li>Choose the best pricing structure for your use case.</li>
                        </ul>
                        <p>Let's talk about how we can help, and schedule a time for you and your team to see a demo of
                            Hyperstack Credential Cloud in action.</p>
                    </aside>
                    <aside className={"form__body"}>
                        <div id="formContainer">
                            <p>Form not showing?
                                <span className={"text-blue pointer"} onClick={()=>this.initializeHubspotForm()}>Reload form</span>
                            </p>
                        </div>
                    </aside>
                </section>

            </Layout>
        )
    }
}

export const Head = () => <Seo
    title="Schedule Demo with Hyperstack"
    description="Get to know how Hyperstack helps you with managing digital credentials and how your privacy is guaranteed using decentralization"
/>

export default ScheduleDemo
